<template>
    <div>
        <Header :seet="number" />
        <el-row
            type="flex"
            class="account—settings"
        >
            <el-col
                :span="3"
                style="width:15.3%;"
            ></el-col>
            <el-col :span="17">
                <div class="settings_box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>账号中心</el-breadcrumb-item>
                        <el-breadcrumb-item @click.native="goto()">账户设置</el-breadcrumb-item>
                        <el-breadcrumb-item>绑定手机号</el-breadcrumb-item>
                    </el-breadcrumb>
                    <div style="display:flex;">
                        <div class="box_left">
                            <LeftHurdle />
                        </div>
                        <div class="box_right">
                            <div class="box_right_top">绑定手机号</div>
                            <div class="box_right_cent">

                                <div class="cent_phone flex-center">

                                    <div class="cent_phone_box">
                                        <div class="flex-start phone_box">
                                            <div class="original_text">手机号:</div>
                                            <el-input
                                                class="phone_input"
                                                v-model.trim="phone"
                                                clearable
                                            ></el-input>
                                        </div>
                                        
                                        <div class="pass_box flex-center">
                                            <div
                                                class="phone_btn"
                                                @click="phoneBtn()"
                                            >提 交</div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </el-col>
            <el-col
                :span="3"
                style="width:15.5%;"
            ></el-col>
        </el-row>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { bindPhone } from "@/api/personage/personage"
import { phoneReg } from '@/utils/regular'
export default {
    components: { Header, Footer, LeftHurdle },
    data() {
        return {
            number: '4',
            phone: '',

        }
    },
    created() {
        this.phone = this.$route.params.userPhone
    },
    methods: {
        changePassword() {
            this.$router.push({ name: 'ChangePassword' })
        },
        phoneBtn() {
            // const reg = /^1[3456789]\d{9}$/
            if (phoneReg.test(this.phone)) {
                let data = {
                    'phone': this.phone
                }
                bindPhone(data).then((data) => {
                    if (data && data.code == "00000") {
                        this.phone = data.data
                        this.$message.success('保存成功！')
                    } else {
                        this.$message.error(data.desc)
                    }
                })
            } else {
                this.$message.error('填写正确的手机号')
            }
        },
        goto() {
            this.$router.push({ name: 'AccountSettings' })
        }
    }

}
</script>

<style lang="scss">
.settings_box {
    margin: 30px 0;
    .el-breadcrumb {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .box_left {
        margin-right: 20px;
    }
    .box_right {
        width: 87%;
        height: 660px;
        border: 1px solid #d7d7d7;
        border-radius: 10px;
        .box_right_top {
            height: 40px;
            line-height: 40px;
            padding-left: 40px;
            background: #d7d7d7;
            border-bottom: 1px solid #d7d7d7;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-weight: bold;
        }
        .cent_phone {
            width: 100%;
            margin-top: 155px;
        }
        .cent_phone_box {
            font-size: 16px;
            color: #666666;
        }
        .phone_box {
            margin-bottom: 30px;
        }
        .original_text {
            width: 80px;
            margin-left: 55px;
        }
        .code_text {
            width: 95px;
            margin-left: 39px;
        }
        .phone_input {
            width: 300px;
        }
        .codephone {
            height: 40px;
            line-height: 40px;
            width: 125px;
            color: #fff;
            font-size: 14px;
            background: #cd9f49;
            text-align: center;
            border-radius: 10px;
            margin-left: 15px;
        }
        .phone_btn {
            height: 40px;
            line-height: 40px;
            width: 125px;
            color: #fff;
            font-size: 14px;
            background: #cd9f49;
            text-align: center;
            border-radius: 10px;
            cursor: pointer;
        }
        .phone_Btn {
            height: 40px;
            line-height: 40px;
            width: 125px;
            color: #333;
            font-size: 14px;
            border: 1px solid #d7d7d7;
            text-align: center;
            border-radius: 10px;
            margin-left: 30px;
        }
    }
}
</style>